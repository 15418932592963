import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, Col, css, styled, thd, up } from '@smooth-ui/core-sc';

import bannerMobile from './images/HomeHeader-bg-mobile-@1x.png';
import bannerMobile2x from './images/HomeHeader-bg-mobile-@2x.png';
import bannerTablet from './images/Header-bg-tablet-@1x.png';
import bannerTablet2x from './images/Header-bg-tablet-@2x.png';
import bannerDesktop from './images/Header-BG@1x.jpg';
import bannerDesktop2x from './images/Header-BG@2x.jpg';

const BannerWrapper = styled.div`
  position: relative;
  z-index: -1;
`;

const BannerContainer = styled.div`
  background-image: url(${bannerMobile});
  align-items: center;
  background-color: ${thd('secondary', '#00A7AD')};
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 435px;
  justify-content: flex-start;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bannerMobile2x});
  }

  .banner__title {
    color: #fff;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
  }
  .banner {
    min-height: 400px;
  }
  ${up(
    'sm',
    css`
      background-image: url(${bannerTablet});
      background-position: left center;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bannerTablet2x});
      }
    `
  )}
  ${up(
    'lg',
    css`
      background-image: url(${bannerDesktop});
      background-position: center center;

      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bannerDesktop2x});
      }
    `
  )}
`;

const HomeBanner = props => (
  <BannerWrapper className="banner-wrapper">
    <BannerContainer {...props}>
      <Grid>
        <Row justifyContent="center" alignItems="center" className="banner">
          <Col xs={12} className="banner__title__wrapper ">
            <div className="banner__title text--center">{props.pageTitle}</div>
          </Col>
        </Row>
      </Grid>
    </BannerContainer>
  </BannerWrapper>
);

HomeBanner.propTypes = {
  pageTitle: PropTypes.object.isRequired,
};

export default HomeBanner;
