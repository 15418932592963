import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled, thd } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import { userProfileRequest } from '_platform/src/containers/App/actions';
import { selectUserProfile } from '_platform/src/containers/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import HomeBanner from 'components/CustomComponents/Banners/HomeBanner';

import Icon1 from './images/Icon1@2x.png';
import Icon2 from './images/Icon2@2x.png';
import Icon3 from './images/Icon3@2x.png';
import Icon4 from './images/Icon4@2x.png';
import Icon5 from './images/Icon5@2x.png';
import LoadAsync from '_platform/src/utils/LoadAsync';
const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);
const GatewayGraph = LoadAsync(() =>
  import(
    /* webpackChunkName: "gatewayGraph" */ '../../components/StatementGateway/GatewayGraph'
  )
);

const StatementStyles = styled.div`
  text-align: center;

  section:not(:last-of-type) {
    border-bottom: 1px solid ${thd('statementHighlightColor', '#f4bd19')};
    padding-bottom: 1.25rem;
  }

  .incentive-tiers {
    border: 2px solid ${thd('primary', '#000')};
    margin-top: 1em;
    padding: 0.5em;
  }

  .incentive-tiers-targets {
    display: flex;
    justify-content: space-around;
  }

  .statement-legend {
    margin-bottom: 2em;
  }

  .client-target-container {
    margin: 1em 0;
  }

  .client-buying-period {
    background-color: ${thd('primary', '#000')};
    color: #fff;
    margin: 0.5em 0;
    padding: 0.5em 0.25em;
  }

  .client-target-selection {
    margin: 0 auto;
    max-width: 420px;
  }
`;

const fetchStatements = [
  {
    endpoint: '/Statement/v1/Loyalty/Pending',
    section: 'loyalty',
  },
  {
    endpoint: '/Statement/v1/Epson/Gateway/',
    section: 'incentive',
  },
];

const HomePage = ({ settings, userProfile }) => {
  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <HomeBanner
          pageTitle={
            <React.Fragment>
              <h2 className="text--uppercase">
                Welcome{' '}
                {userProfile && userProfile.data && userProfile.data.firstName}{' '}
                to
                <br />
                Epson Premium Partner Rewards
              </h2>
            </React.Fragment>
          }
        />

        <Grid>
          <Row justifyContent="center">
            <Col md={8} className="text--center">
              <p>
                Here at Epson, we value your contribution to our business and
                have created Premium Partner Rewards to ensure that you are
                rewarded for your loyalty and on-going commitment!
              </p>
              <hr />
            </Col>
          </Row>
          <Row justifyContent="center">
            <Col xs={12} md={6} className="text--center">
              <PrivateComponent displayError>
                <StatementStyles>
                  <StatementProvider
                    fetch={fetchStatements}
                    render={({ statement }) => {
                      return !statement ||
                        !statement.data ||
                        !statement.data.incentive ? (
                        <LoadingAnimation /> // Loader instead of `null`?
                      ) : (
                        <GatewayGraph
                          gatewayTarget={statement.data.incentive}
                        />
                      );
                    }}
                  />
                </StatementStyles>
              </PrivateComponent>
            </Col>
          </Row>
          <Row justifyContent="center">
            <Col md={8} className="text--center">
              <hr />
              <h3 className="text--uppercase">How it works</h3>
              <p>
                The more you sell the quicker you will reach your target to
                unlock your rewards!
              </p>
              <p>
                Simply sell any eligible Epson product, submit your sale via the
                “Claims” page, and hit your “Gateway Target”, to unlock points
                that you can redeem in our rewards catalogue.
              </p>
              <p>
                Claims are approved once your sale has been validated. Every
                eligible Epson product sold earns points. So, start selling and
                watch your points accumulate towards your Gateway Target today!
              </p>
            </Col>
          </Row>
          <Row justifyContent="center" className="spacer--large">
            <Col xs={6} md={4} lg={2} className="text--center">
              <img src={Icon1} alt="SALES" width="118" />
              <h4 className="text--uppercase">SALES</h4>
              <p>
                Sell eligible
                <br />
                Epson products
              </p>
            </Col>
            <Col xs={6} md={4} lg={2} className="text--center">
              <img src={Icon2} alt="CLAIM" width="118" />
              <h4 className="text--uppercase">CLAIM</h4>
              <p>
                Submit your
                <br />
                sales claim
              </p>
            </Col>
            <Col xs={6} md={4} lg={2} className="text--center">
              <img src={Icon3} alt="EARN" width="118" />
              <h4 className="text--uppercase">EARN</h4>
              <p>
                Watch your
                <br />
                points Add up
              </p>
            </Col>
            <Col xs={6} md={4} lg={2} className="text--center">
              <img src={Icon4} alt="Gateway" width="118" />
              <h4 className="text--uppercase">Gateway</h4>
              <p>
                Hit your
                <br />
                Gateway Target
              </p>
            </Col>
            <Col xs={6} md={4} lg={2} className="text--center">
              <img src={Icon5} alt="REDEEM" width="118" />
              <h4 className="text--uppercase">REDEEM</h4>
              <p>
                Redeem your
                <br />
                points for rewards!
              </p>
            </Col>
          </Row>
        </Grid>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  onUserProfileRequest: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  userProfile: PropTypes.object,
};

HomePage.defaultProps = {
  userProfile: undefined,
};

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUserProfileRequest: () => dispatch(userProfileRequest()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withSettings, withConnect)(HomePage);
